import React, { Component, useEffect, useState } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
//var CanvasJSReact = require('@canvasjs/react-charts');
import Loader from '../../utils/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import Swal from "sweetalert2";
import { GetResultChartRequest } from '../../redux/reducer/FetchCompanyDataReducer';
import Accordion from 'react-bootstrap/Accordion';
import ApiConnection from '../../utils/ApiConnection'
import moment from "moment";
import 'bootstrap/dist/css/bootstrap.min.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const AssessmentChart = () => {

	const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    const [openAccordions, setOpenAccordions] = useState(["0", "1"]); // Initially open both accordions

	const ChartDetails = fetchacompanyReducer?.GetResultChartResponse?.results
	console.log(ChartDetails)

	var result = [
		{
			topic_name: "Topic one",
			total_topic: 6,
			correct_topic: {
				total: 3,
				question_no: '1,5'
			},
			incorrect_topic: {
				total: 2,
				question_no: '2,3'
			},
			not_attempt_topic: {
				total: 1,
				question_no: '4'
			},

		},
		{
			topic_name: "Topic two",
			total_topic: 10,
			correct_topic: {
				total: 2,
				question_no: '6,7'
			},
			incorrect_topic: {
				total: 5,
				question_no: '8,9'
			},
			not_attempt_topic: {
				total: 3,
				question_no: '4'
			},

		},
		{
			topic_name: "Topic three",
			total_topic: 10,
			correct_topic: {
				total: 2,
				question_no: '6,7'
			},
			incorrect_topic: {
				total: 8,
				question_no: '8,9'
			},
			not_attempt_topic: {
				total: 0,
				question_no: ''
			},

		}
	]

	const [chartdata, setchartdata] = useState(result)
	const [totalquestion, settotalquestion] = useState([])
	let dispatch = useDispatch()
	let navigate = useNavigate()

	const { id, pros } = useParams()


	useEffect(() => {
		dispatch(GetResultChartRequest({ test_id: id, student_id: pros }))
	}, [])

    const handlePrint = () => {
        // Open all accordions
        setOpenAccordions(["0", "1"]); // Open all accordions

        // Wait for the state to update
        setTimeout(() => {
            let printContents = document.getElementById('PrintDiv').innerHTML;
            let originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;

            // Restore original accordion state
            setOpenAccordions([]); // Restore to closed state or whatever the original state was
			window.location.reload();
        }, 10); // Delay to ensure the state has updated
    };

    const toggleAccordion = (eventKey) => {
        setOpenAccordions((prev) => {
            if (prev.includes(eventKey)) {
                return prev.filter((key) => key !== eventKey);
            } else {
                return [...prev, eventKey];
            }
        });
    };

	useEffect(() => {

		var totalLength = fetchacompanyReducer?.GetResultChartResponse?.results?.total_question_count
		let pagArr = []
		for (let i = 0; i < totalLength; i++) {
			pagArr.push(i)
		}
		settotalquestion(pagArr)

	}, [fetchacompanyReducer?.GetResultChartResponse])


	console.log(fetchacompanyReducer?.GetResultChartResponse)


	const truncateString = (str, num) => {
        if (str.length > num) {
            return str.slice(0, num) + '...'; // Add ellipsis if truncated
        }
        return str;
    };

	return (
		<div className='common-layout'>
			<Loader visible={fetchacompanyReducer.isloading} />
			<div className='innerlayout p-3'>
				<div className='mb-4'>
					<h3>Assessment chart</h3>
				</div>

				<div className='button-part mb-4' style={{ marginLeft: '810px', marginTop: '-67px' }}>
					<ul>
						<li>
							<button className='formBtn1' onClick={handlePrint}>Print</button>
						</li>
						<li>
							<button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
						</li>
					</ul>
				</div>

            <div id="PrintDiv">
				<div className='view-page'>

				<Accordion defaultActiveKey={openAccordions} className='view-accordian'>
    <Accordion.Item eventKey="0">
        <Accordion.Header onClick={() => toggleAccordion("0")}>Student Information</Accordion.Header>
        <Accordion.Body>
            <div className='row center-align'>
                <div className='col-lg-6 col-md-12'>
                    <div className='form-group d-flex align-items-center'>
                        <label className='d-block mr-2'>Name :</label>
                        <b>{ChartDetails?.student_name}</b>
                    </div>
                </div>
				<div className='col-lg-6 col-md-12'>
                    <div className='form-group d-flex align-items-center'>
                        <label className='d-block mr-2'>Id :</label>
                        <b>{ChartDetails?.student_id}</b>
                    </div>
                </div>
                {/* <div className='col-lg-6 col-md-12'>
                    <div className='form-group d-flex align-items-center'>
                        <label className='d-block mr-2'>School Name :</label>
                        <b>{ChartDetails?.school_name}</b>
                    </div>
                </div> */}
                <div className='col-lg-6 col-md-12'>
                    <div className='form-group d-flex align-items-center'>
                        <label className='d-block mr-2'>Grade :</label>
                        <b>{ChartDetails?.grade_name}</b>
                    </div>
                </div>
                {/* <div className='col-lg-6 col-md-12'>
                    <div className='form-group d-flex align-items-center'>
                        <label className='d-block mr-2'>Mobile:</label>
                        <b>{ChartDetails?.mobile_phone}</b>
                    </div>
                </div> */}
                {/* Uncomment if needed
                <div className='col-lg-6 col-md-12'>
                    <div className='form-group d-flex align-items-center'>
                        <label className='d-block mr-2'>Date of birth:</label>
                        <b>{moment(ChartDetails?.date_of_birth).format("YYYY-MM-DD")}</b>
                    </div>
                </div>
                */}
            </div>
            <hr />
        </Accordion.Body>
    </Accordion.Item>
</Accordion>

<Accordion defaultActiveKey={openAccordions} className='view-accordian'>
    <Accordion.Item eventKey="1">
        <Accordion.Header onClick={() => toggleAccordion("1")}>Assessment Information</Accordion.Header>
        <Accordion.Body>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <div className='form-group d-flex align-items-center'>
                        <label className='d-block mr-2'>Assessment Name :</label>
                        <b>{ChartDetails?.test_name}</b>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='form-group d-flex align-items-center'>
                        <label className='d-block mr-2'>Group :</label>
                        <b>{ChartDetails?.group_name}</b>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='form-group d-flex align-items-center'>
                        <label className='d-block mr-2'>Level :</label>
                        <b>{ChartDetails?.level}</b>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='form-group d-flex align-items-center'>
                        <label className='d-block mr-2'>Date Taken :</label>
                        <b>{moment(ChartDetails?.exam_date).format("YYYY-MM-DD")}</b>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='form-group d-flex align-items-center'>
                        <label className='d-block mr-2'>Score :</label>
                        <b>{ChartDetails?.total_percantege_obtained} %</b>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='form-group d-flex align-items-center'>
                        <label className='d-block mr-2'>Total Marks :</label>
                        <b>{ChartDetails?.total_marks}</b>
                    </div>
                </div>
            </div>
            <hr />
        </Accordion.Body>
    </Accordion.Item>
</Accordion>

				</div>


				<div className='chart_view' style={{ marginTop: '40px' }} >
					<div className='chart_view_horizental'>
						{fetchacompanyReducer?.GetResultChartResponse?.results?.results.map((item) => {

							var Width = (100 / fetchacompanyReducer?.GetResultChartResponse?.results?.total_question_count) * (item.total_topic)
							var correctWidth = (item?.correct_topic?.total / item.total_topic) * 100
							var IncorrectWidth = (item?.incorrect_topic?.total / item.total_topic) * 100
							var notAttemptWidth = (item?.not_attempt_topic?.total / item.total_topic) * 100

							return (
								
								<div className='topic_list' key={item.topic_name}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
											<Tooltip id={`tooltip-top`}
											style={{
												whiteSpace: 'nowrap', // Prevent text from wrapping
												maxWidth: '800px', // Set a maximum width for the tooltip
												overflow: 'visible', // Allow overflow to be visible
												textOverflow: 'ellipsis', // Add ellipsis for overflow text
												zIndex: 9999 // Ensure the tooltip appears above other elements
											}}>
												{item.topic_name}
											</Tooltip>
										}
                                    >
                                        <label>{truncateString(item.topic_name, 15)}</label>
                                    </OverlayTrigger>
									<div className='chart_bg' style={{ width: `${Width}%` }}>
										<div className='chart_bg_correct' style={{ width: `${correctWidth}%`, background: 'green', height: '25px' }}>
											<div className='hover-info'>
												<h5>{item.topic_name}</h5>
												<h6>Question No: {item.correct_topic.question_no}</h6>
											</div>
										</div>
										<div className='chart_bg_incorrect' style={{ width: `${IncorrectWidth}%`, background: 'orange', height: '25px' }}>
											<div className='hover-info'>
												<h5>{item.topic_name}</h5>
												<h6>Question No: {item.incorrect_topic.question_no}</h6>
											</div>
										</div>
										<div className='chart_bg_notattempt' style={{ width: `${notAttemptWidth}%`, background: 'red', height: '25px' }}>
											<div className='hover-info'>
												<h5>{item.topic_name}</h5>
												<h6>Question No: {item.not_attempt_topic.question_no}</h6>
											</div>
										</div>
									</div>
								</div>
							)
						})}

					</div>
					<div className='chart_view_verticle'>
						<ul>
							{totalquestion && totalquestion.map((index) => {
								return (
									<li style={{ width: `${100 / totalquestion.length}%` }}>{index + 1}</li>
								)
							})}
						</ul>
					</div>
					<div className='for-suggestion'>
						<div className='for-suggestion-b'>
							<span style={{ background: 'green' }}></span>
							<label>Correct</label>
						</div>
						<div className='for-suggestion-b'>
							<span style={{ background: 'orange' }}></span>
							<label>Incorrect</label>
						</div>
						<div className='for-suggestion-b'>
							<span style={{ background: 'red' }}></span>
							<label>Not Attempt</label>
						</div>
					</div>

					<div className='table-responsive mt-4'>
						<table className='chart-table-border'>
							<tr>
								<th rowSpan="2">Topics Name</th>
								<th colSpan="2" className='text-center text-success'>Correct</th>
								<th colSpan="2" className='text-center text-warning'>Incorrect</th>
								<th colSpan="2" className='text-center text-danger'>Not Attempt</th>
							</tr>
							<tr>
								<th className='text-center'>Total</th>
								<th className='text-center'>Q.No</th>
								<th className='text-center'>Total</th>
								<th className='text-center'>Q.No</th>
								<th className='text-center'>Total</th>
								<th className='text-center'>Q.No</th>
							</tr>
							{fetchacompanyReducer?.GetResultChartResponse?.results?.results.map((item, i) => {
								return (
									<tr key={i}>
										<td>{item?.topic_name}</td>
										<td>
											{item?.correct_topic?.total}
										</td>
										<td>
											{item.correct_topic.question_no}
										</td>
										<td>
											{item?.incorrect_topic?.total}
										</td>
										<td>
											{item.incorrect_topic.question_no}
										</td>
										<td>
											{item?.not_attempt_topic?.total}
										</td>
										<td>
											{item.not_attempt_topic.question_no}
										</td>


									</tr>
								)
							})}
						</table>
					</div>

					{/* <ol className='list_result'>
					{fetchacompanyReducer?.GetResultChartResponse?.results?.results.map((item, i)=>{
						return (
							<li key={i}>
								<h4>{item?.topic_name}</h4>
								<ul>
									<li>
										<b style={{color:'green'}}>Correct</b>
										<p>Total Question: {item?.correct_topic?.total}</p>
										<p>Question No: {item.correct_topic.question_no}</p>
									</li>
									<li>
										<b style={{color:'orange'}}>Incorrect</b>
										<p>Total Question: {item?.incorrect_topic?.total}</p>
										<p>Question No: {item.incorrect_topic.question_no}</p>
									</li>
									<li>
										<b style={{color:'red'}}>Not Attempt</b>
										<p>Total Question: {item?.not_attempt_topic?.total}</p>
										<p>Question No: {item.not_attempt_topic.question_no}</p>
									</li>
								</ul>
								<div>
									
									
									
								</div>
							</li>
						)
					})}
				
				</ol> */}


				</div>
				</div>

				{/* <CanvasJSChart options = {options}/> */}

			</div>
		</div>
	);
}
export default AssessmentChart