import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../utils/Loader';
import { EditCompanyRequest, UpdateCompanyRequest } from '../../redux/reducer/FetchAdminDataReducer';
import { useNavigate, useParams } from 'react-router-dom';
import moment from "moment";
import { GetStateRequest, GetcityRequest } from '../../redux/reducer/FetchCompanyDataReducer';
import axios from 'axios'

const EditCompany = () => {

    const [cname, setcname] = useState("")
    const [cslug, setcslug] = useState(""); // State for Company Slug
    const [title, settitle] = useState("")
    const [salution, setsalution] = useState("")
    const [fname, setfname] = useState("")
    const [lname, setlname] = useState("")
    const [email, setemail] = useState("")
    const [phone, setphone] = useState("")
    const [countryCode, setcountryCode] = useState("+1")
    const [countryCodes, setcountryCodes] = useState([])
    const [countryId, setcountryId] = useState("")
    const [stateId, setstateId] = useState("")
    const [cityId, setcityId] = useState("")
    const [addressone, setaddressone] = useState("")
    const [addresstwo, setaddresstwo] = useState("")
    const [country, setcountry] = useState("")
    const [stateList, setstateList] = useState([])
    const [state, setstate] = useState("")
    const [city, setcity] = useState("")
    const [cityList, setcityList] = useState([])
    const [zipcode, setzipcode] = useState("")

    const [status, setstatus] = useState("active")

    
    const dispatch = useDispatch()
    let navigate = useNavigate()
    let {id} = useParams()
    const fetchReducer = useSelector((state) => state.FetchAdminDataReducer)
    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)

    console.log(fetchReducer)

    let pharam = {
        'id': id,
    }

useEffect(()=>{
    dispatch(EditCompanyRequest(pharam))
},[])


useEffect(() => {
    if (fetchReducer?.EditCompanyResponse) {
        const response = fetchReducer.EditCompanyResponse;

        setcname(response.company_name);
        setcslug(response.company_slug);
        settitle(response.title_of_person_in_charge);
        setfname(response.first_name);
        setlname(response.last_name);
        setemail(response.email);
        setzipcode(response.zipcode);
        setaddressone(response.street_address_1);
        setaddresstwo(response.street_address_2);
        setstatus(response.status);

        const mobilePhone = response.phone || "";
        const last10Digits = mobilePhone.slice(-10);
        const stdCode = response.std_code || "+1";
        setphone(last10Digits);
        setcountryCode(stdCode); 
        setcountry(response.country_name);
        setcountryId(response.country);
        setstateId(response.state);
        setcityId(response.city);
        setstate(response.state_name);
        setcity(response.city_name);

        dispatch(GetStateRequest({ id: response.country }));
        dispatch(GetcityRequest({ id: response.state }));
    }
}, [fetchReducer?.EditCompanyResponse, dispatch]);


useEffect(() => {
    const fetchCountryCodes = async () => {
        try {
            const response = await axios.get('https://restcountries.com/v3.1/all');
            const countryIdMap = {
                "Afghanistan": 1,
                "Aland Islands": 2,
                "Albania": 3,
                "Algeria": 4,
                "American Samoa": 5,
                "Andorra": 6,
                "Angola": 7,
                "Anguilla": 8,
                "Antarctica": 9,
                "Antigua And Barbuda": 10,
                "Argentina": 11,
                "Armenia": 12,
                "Aruba": 13,
                "Australia": 14,
                "Austria": 15,
                "Azerbaijan": 16,
                "Bahrain": 17,
                "Bangladesh": 18,
                "Barbados": 19,
                "Belarus": 20,
                "Belgium": 21,
                "Belize": 22,
                "Benin": 23,
                "Bermuda": 24,
                "Bhutan": 25,
                "Bolivia": 26,
                "Bonaire, Sint Eustatius and Saba": 27,
                "Bosnia and Herzegovina": 28,
                "Botswana": 29,
                "Bouvet Island": 30,
                "Brazil": 31,
                "British Indian Ocean Territory": 32,
                "Brunei": 33,
                "Bulgaria": 34,
                "Burkina Faso": 35,
                "Burundi": 36,
                "Cambodia": 37,
                "Cameroon": 38,
                "Canada": 39,
                "Cape Verde": 40,
                "Cayman Islands": 41,
                "Central African Republic": 42,
                "Chad": 43,
                "Chile": 44,
                "China": 45,
                "Christmas Island": 46,
                "Cocos (Keeling) Islands": 47,
                "Colombia": 48,
                "Comoros": 49,
                "Congo": 50,
                "Cook Islands": 51,
                "Costa Rica": 52,
                "Cote D'Ivoire (Ivory Coast)": 53,
                "Croatia": 54,
                "Cuba": 55,
                "Curaçao": 56,
                "Cyprus": 57,
                "Czech Republic": 58,
                "Democratic Republic of the Congo": 59,
                "Denmark": 60,
                "Djibouti": 61,
                "Dominica": 62,
                "Dominican Republic": 63,
                "East Timor": 64,
                "Ecuador": 65,
                "Egypt": 66,
                "El Salvador": 67,
                "Equatorial Guinea": 68,
                "Eritrea": 69,
                "Estonia": 70,
                "Falkland Islands": 71,
                "Hondura": 72,
                "Faroe Islands": 73,
                "Fiji Islands": 74,
                "Finland": 75,
                "France": 76,
                "French Guiana": 77,
                "French Polynesia": 78,
                "French Southern Territories": 79,
                "Gabon": 80,
                "Gambia The": 81,
                "Georgia": 82,
                "Germany": 83,
                "Ghana": 84,
                "Gibraltar": 85,
                "Greece": 86,
                "Greenland": 87,
                "Grenada": 88,
                "Guadeloupe": 89,
                "Guam": 90,
                "Guatemala": 91,
                "Guernsey and Alderneyia": 92,
                "Guinea": 93,
                "Guinea-Bissau": 94,
                "Guyana": 95,
                "Haiti": 96,
                "Heard Island and McDonald Islands": 97,
                "Honduras": 98,
                "Hong Kong S.A.R.": 99,
                "Hungary": 100,
                "Iceland": 101,
                "India": 102,
                "Indonesia": 103,
                "Iran": 104,
                "Iraq": 105,
                "Ireland": 106,
                "Israel": 107,
                "Italy": 108,
                "Jamaica": 109,
                "Japan": 110,
                "Jersey": 111,
                "Jordan": 112,
                "Kazakhstan": 113,
                "Kenya": 114,
                "Kiribati": 115,
                "Kosovo": 116,
                "Kuwait": 117,
                "Kyrgyzstan": 118,
                "Laos": 119,
                "Latvia": 120,
                "Lebanon": 121,
                "Lesotho": 122,
                "Liberia ": 123,
                "Libya": 124,
                "Liechtenstein": 125,
                "Lithuania": 126,
                "Luxembourg": 127,
                "Macau S.A.R.": 128,
                "Macedonia": 129,
                "Madagascar": 130,
                "Malawi": 131,
                "Malaysia": 132,
                "Maldives": 133,
                "Mali": 134,
                "Malta": 135,
                "Man (Isle of)": 136,
                "Marshall Islands": 137,
                "Martinique": 138,
                "Mauritania": 139,
                "Mauritius": 140,
                "Mayotte": 141,
                "Mexico": 142,
                "Micronesia": 143,
                "Moldova": 144,
                "Monaco": 145,
                "Mongolia": 146,
                "Montenegro": 147,
                "Montserrat": 148,
                "Morocco": 149,
                "Mozambique": 150,
                "Myanmar": 151,
                "Namibia": 152,
                "Nauru": 153,
                "Nepal": 154,
                "Netherlands": 155,
                "New Caledonia": 156,
                "New Zealand": 157,
                "Nicaragua": 158,
                "Niger": 159,
                "Nigeria": 160,
                "Niue": 161,
                "Norfolk Island": 162,
                "North Korea": 163,
                "Northern Mariana Islands": 164,
                "Norway": 165,
                "Oman": 166,
                "Pakistan": 167,
                "Palau": 168,
                "Palestinian Territory Occupied": 169,
                "Panama": 170,
                "Papua new Guinea": 171,
                "Paraguay": 172,
                "Peru": 173,
                "Philippines": 174,
                "Pitcairn Island": 175,
                "Poland": 176,
                "Portugal": 177,
                "Puerto Rico": 178,
                "Qatar": 179,
                "Reunion": 180,
                "Romania": 181,
                "Russia": 182,
                "Rwanda": 183,
                "Saint Helena": 184,
                "Saint Kitts And Nevis": 185,
                "Saint Lucia": 186,
                "Saint Pierre and Miquelon": 187,
                "Saint Vincent And The Grenadines": 188,
                "Saint-Barthelemy": 189,
                "Saint-Martin (French part)": 190,
                "Samoa": 191,
                "San Marino": 192,
                "Sao Tome and Principe": 193,
                "Saudi Arabia": 194,
                "Senegal": 195,
                "Serbia": 196,
                "Seychelles": 197,
                "Sierra Leone": 198,
                "Singapore": 199,
                "Sint Maarten (Dutch part)": 200,
                "Slovakia": 201,
                "Slovenia": 202,
                "Solomon Islands": 203,
                "Somalia": 204,
                "South Africa": 205,
                "South Georgia": 206,
                "South Korea": 207,
                "South Sudan": 208,
                "Spain": 209,
                "Sri Lanka": 210,
                "Sudan": 211,
                "Suriname": 212,
                "Svalbard And Jan Mayen Islands": 213,
                "Swaziland": 214,
                "Sweden": 215,
                "Switzerland": 216,
                "Syria": 217,
                "Taiwan": 218,
                "Tajikistan": 219,
                "Tanzania": 220,
                "Thailand": 221,
                "The Bahamas": 222,
                "Togo": 223,
                "Tokelau": 224,
                "Tonga": 225,
                "Trinidad And Tobago": 226,
                "Tunisia": 227,
                "Turkey": 228,
                "Turkmenistan": 229,
                "Turks And Caicos Islands": 230,
                "Tuvalu": 231,
                "Uganda": 232,
                "Ukraine": 233,
                "United Arab Emirates": 234,
                "United Kingdom": 235,
                "United States": 236,
                "United States Minor Outlying Islands": 237,
                "Uruguay": 238,
                "Uzbekistan": 239,
                "Vanuatu": 240,
                "Vatican City State (Holy See)": 241,
                "Venezuela": 242,
                "Vietnam": 243,
                "Virgin Islands (British)": 244,
                "Virgin Islands (US)": 245,
                "Wallis And Futuna Islands": 246,
                "Western Sahara": 247,
                "Yemen": 248,
                "Zambia": 249,
                "Zimbabwe": 250
            };
            const codes = response.data.map(country => {
                 const predefinedCode = predefinedCountryCodes[country.name.common];
                const code = predefinedCode || `${country.idd.root}${country.idd.suffixes ? country.idd.suffixes[0] : ''}`;
                return {
                    code,
                    name: country.name.common,
                    flag: country.flags.svg,
                    id: countryIdMap[country.name.common] || country.ccn3
                };
            });
            console.log("Testcodes", codes);
            setcountryCodes(codes);
        } catch (e) {
            console.error(e);
        }
    };
    fetchCountryCodes();
}, []);

const predefinedCountryCodes = {
    'Afghanistan': '+93',
    'Albania': '+355',
    'Algeria': '+213',
    'Andorra': '+376',
    'Angola': '+244',
    'Antigua and Barbuda': '+1-268',
    'Argentina': '+54',
    'Armenia': '+374',
    'Australia': '+61',
    'Austria': '+43',
    'Azerbaijan': '+994',
    'Bahamas': '+1-242',
    'Bahrain': '+973',
    'Bangladesh': '+880',
    'Barbados': '+1-246',
    'Belarus': '+375',
    'Belgium': '+32',
    'Belize': '+501',
    'Benin': '+229',
    'Bhutan': '+975',
    'Bolivia': '+591',
    'Bosnia and Herzegovina': '+387',
    'Botswana': '+267',
    'Brazil': '+55',
    'Brunei': '+673',
    'Bulgaria': '+359',
    'Burkina Faso': '+226',
    'Burundi': '+257',
    'Cabo Verde': '+238',
    'Cambodia': '+855',
    'Cameroon': '+237',
    'Canada': '+1',
    'Central African Republic': '+236',
    'Chad': '+235',
    'Chile': '+56',
    'China': '+86',
    'Colombia': '+57',
    'Comoros': '+269',
    'Congo, Democratic Republic of the': '+243',
    'Congo, Republic of the': '+242',
    'Costa Rica': '+506',
    'Croatia': '+385',
    'Cuba': '+53',
    'Cyprus': '+357',
    'Czech Republic': '+420',
    'Denmark': '+45',
    'Djibouti': '+253',
    'Dominica': '+1-767',
    'Dominican Republic': '+1-809',
    'Ecuador': '+593',
    'Egypt': '+20',
    'El Salvador': '+503',
    'Equatorial Guinea': '+240',
    'Eritrea': '+291',
    'Estonia': '+372',
    'Eswatini': '+268',
    'Ethiopia': '+251',
    'Fiji': '+679',
    'Finland': '+358',
    'France': '+33',
    'Gabon': '+241',
    'Gambia': '+220',
    'Georgia': '+995',
    'Germany': '+49',
    'Ghana': '+233',
    'Greece': '+30',
    'Grenada': '+1-473',
    'Guatemala': '+502',
    'Guinea': '+224',
    'Guinea-Bissau': '+245',
    'Guyana': '+592',
    'Haiti': '+509',
    'Honduras': '+504',
    'Hungary': '+36',
    'Iceland': '+354',
    'India': '+91',
    'Indonesia': '+62',
    'Iran': '+98',
    'Iraq': '+964',
    'Ireland': '+353',
    'Israel': '+972',
    'Italy': '+39',
    'Jamaica': '+1-876',
    'Japan': '+81',
    'Jersey': '+44-1534',
    'Jordan': '+962',
    'Kazakhstan': '+7',
    'Kenya': '+254',
    'Kiribati': '+686',
    'Kuwait': '+965',
    'Kyrgyzstan': '+996',
    'Laos': '+856',
    'Latvia': '+371',
    'Lebanon': '+961',
    'Lesotho': '+266',
    'Liberia': '+231',
    'Libya': '+218',
    'Liechtenstein': '+423',
    'Lithuania': '+370',
    'Luxembourg': '+352',
    'Madagascar': '+261',
    'Malawi': '+265',
    'Malaysia': '+60',
    'Maldives': '+960',
    'Mali': '+223',
    'Malta': '+356',
    'Marshall Islands': '+692',
    'Mauritania': '+222',
    'Mauritius': '+230',
    'Mexico': '+52',
    'Micronesia': '+691',
    'Moldova': '+373',
    'Monaco': '+377',
    'Mongolia': '+976',
    'Montenegro': '+382',
    'Morocco': '+212',
    'Mozambique': '+258',
    'Myanmar': '+95',
    'Namibia': '+264',
    'Nauru': '+674',
    'Nepal': '+977',
    'Netherlands': '+31',
    'New Zealand': '+64',
    'Nicaragua': '+505',
    'Niger': '+227',
    'Nigeria': '+234',
    'North Macedonia': '+389',
    'Norway': '+47',
    'Oman': '+968',
    'Pakistan': '+92',
    'Palau': '+680',
    'Palestine': '+970',
    'Panama': '+507',
    'Papua New Guinea': '+675',
    'Paraguay': '+595',
    'Peru': '+51',
    'Philippines': '+63',
    'Poland': '+48',
    'Portugal': '+351',
    'Qatar': '+974',
    'Romania': '+40',
    'Russia': '+7',
    'Rwanda': '+250',
    'Saint Kitts and Nevis': '+1-869',
    'Saint Lucia': '+1-758',
    'Saint Vincent and the Grenadines': '+1-784',
    'Samoa': '+685',
    'San Marino': '+378',
    'Sao Tome and Principe': '+239',
    'Saudi Arabia': '+966',
    'Senegal': '+221',
    'Serbia': '+381',
    'Seychelles': '+248',
    'Sierra Leone': '+232',
    'Singapore': '+65',
    'Slovakia': '+421',
    'Slovenia': '+386',
    'Solomon Islands': '+677',
    'Somalia': '+252',
    'South Africa': '+27',
    'South Korea': '+82',
    'South Sudan': '+211',
    'Spain': '+34',
    'Sri Lanka': '+94',
    'Sudan': '+249',
    'Suriname': '+597',
    'Sweden': '+46',
    'Switzerland': '+41',
    'Syria': '+963',
    'Taiwan': '+886',
    'Tajikistan': '+992',
    'Tanzania': '+255',
    'Thailand': '+66',
    'Togo': '+228',
    'Tonga': '+676',
    'Trinidad and Tobago': '+1-868',
    'Tunisia': '+216',
    'Turkey': '+90',
    'Turkmenistan': '+993',
    'Tuvalu': '+688',
    'Uganda': '+256',
    'Ukraine': '+380',
    'United Arab Emirates': '+971',
    'United Kingdom': '+44',
    'United States': '+1',
    'Uruguay': '+598',
    'Uzbekistan': '+998',
    'Vanuatu': '+678',
    'Vatican City': '+39',
    'Venezuela': '+58',
    'Vietnam': '+84',
    'Yemen': '+967',
    'Zambia': '+260',
    'Zimbabwe': '+263'
};

useEffect(() => {
    setstateList(fetchacompanyReducer?.GetstateResponse || []);
}, [fetchacompanyReducer?.GetstateResponse]);

useEffect(() => {
    setcityList(fetchacompanyReducer?.GetcityResponse || []);
}, [fetchacompanyReducer?.GetcityResponse]);

const CountryHandle = (e) => {
    const selectedCountry = e.target.value;
    const selectedCountryData = countryCodes.find(c => c.name === selectedCountry);
    console.log(selectedCountryData)

    if (selectedCountryData) {
        setcountryId(selectedCountryData.id);
        setcountry(selectedCountry);
        
        // Set the country code based on the selected country
        const dialCode = predefinedCountryCodes[selectedCountry] || '';
        setcountryCode(dialCode);
        // Fetch states based on the selected country
        dispatch(GetStateRequest({ id: selectedCountryData.id }));
        // Reset state and city when country changes
        setstate("");
        setcity("");
    } else {
        console.error("Selected country data not found");
    }
};

const stateHandle = (e) => {
    const selectedStateId = e.target.value;
    setstate(selectedStateId);
    // Fetch cities based on the selected state
    if (selectedStateId) {
        dispatch(GetcityRequest({ id: selectedStateId }));
    } else {
        // Handle case where state ID is not valid
        console.error("Selected state ID is invalid");
    }
};
    
    const SaveHandle = ()=>{

        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

        if (cname == '') {
            Swal.fire({
                icon: "error",
                text: 'Company name should be mandatory',
            });
        } else if (title == '') {
            Swal.fire({
                icon: "error",
                text: 'Title of Person In-Charge should be mandatory',
            });
        } else if (fname == '') {
            Swal.fire({
                icon: "error",
                text: 'First name should be mandatory',
            });
        } else if (lname == '') {
            Swal.fire({
                icon: "error",
                text: 'Last name should be mandatory',
            });
        } else if (addressone == '') {
            Swal.fire({
                icon: "error",
                text: 'Address should be mandatory',
            });
        } else if (country == '') {
            Swal.fire({
                icon: "error",
                text: 'Country should be mandatory',
            });
        } else if (state == '') {
            Swal.fire({
                icon: "error",
                text: 'State should be mandatory',
            });
        } else if (city == '') {
            Swal.fire({
                icon: "error",
                text: 'City should be mandatory',
            });
        } else if (zipcode == '') {
            Swal.fire({
                icon: "error",
                text: 'Zipcode should be mandatory',
            });
        } else if (zipcode.length !== 5) {
            Swal.fire({
                icon: "error",
                text: 'Zipcode should be 5 digit',
            });
        } else if (phone == '') {
            Swal.fire({
                icon: "error",
                text: 'Mobile no. should be mandatory',
            });
        } else if (phone.length < 10) {
            Swal.fire({
                icon: "error",
                text: 'Mobile no. should be 10 digit',
            });
        } else if (reg.test(email) === false) {
            Swal.fire({
                icon: "error",
                text: 'Email should be proper',
            });
       } else {

        let pharam = {
            'id': id,
            'method':'edit'
        }

        let body = {
            "company_name": cname,
            "title_of_person_in_charge": title,
            "email": email,
            "salutation": salution,
            "first_name": fname,
            "last_name": lname,
            "phone": phone,
            "status": status,
            "street_address_1": addressone,
            "street_address_2": addresstwo,
            "country_id": countryId,
            "state_id": stateId,
            "city_id": cityId,
            "zipcode": Number(zipcode),
        }

        dispatch(UpdateCompanyRequest({obj:body,pharam:pharam,navigate:navigate}))

       }
    }

    return (
        <div className='common-layout'>
            <Loader visible={fetchReducer.isloading}  />
        <div className='innerlayout p-3'>
         <div className='mb-4'>
         <h3>Edit Company</h3>
         </div>
        
     
       <div className='row form-style mb-4'>
           <div className='col-lg-4'>
               <div className='form-group'>
                   <label className='selectlabel'>Company <span class="asterisk">*</span></label>
                   <input type='text' className='form-control' placeholder='Company Name'
                   value={cname}
                   onChange={(e)=>setcname(e.target.value)}
                   />
               </div>
           </div>

           <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Company Slug <span className="asterisk">*</span></label>
                            <input type='text' disabled className='form-control' placeholder='Company Slug'
                                value={cslug}
                                onChange={(e) => setcslug(e.target.value)}
                            />
                        </div>
                    </div>

           <div className='col-lg-4'>
               <div className='form-group'>
                   <label className='selectlabel'>Title of Person In-Charge <span class="asterisk">*</span></label>
                   <input type='text' className='form-control' placeholder='Title of Person In-Charge'
                   value={title}
                   onChange={(e)=>settitle(e.target.value)}
                   />
               </div>
           </div>
           <div className='col-lg-4'>
               <div className='form-group'>
                   <label className='selectlabel'>Status <span class="asterisk">*</span></label>
                  <select className='form-control'  value={status}
                   onChange={(e)=>setstatus(e.target.value)}>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
               </div>
           </div>

           <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select Salutation </label>
                            <select className='form-control' value={salution}
                                onChange={(e) => setsalution(e.target.value)}>
                                <option value="">--Salutation---</option>
                                <option value="Mr">Mr</option>
                                <option value="Ms">Ms</option>
                                <option value="Mrs">Mrs</option>
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>First Name <span class="asterisk">*</span></label>
                            <input type='text' className='form-control' placeholder='First Name'
                                value={fname}
                                onChange={(e) => setfname(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Last Name <span class="asterisk">*</span></label>
                            <input type='text' className='form-control' placeholder='Last Name'
                                value={lname}
                                onChange={(e) => setlname(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Enter Address 1 <span class="asterisk">*</span></label>
                            <input type='text' className={`form-control`} placeholder='Street Address 1'
                                value={addressone}
                                onChange={(e) => { setaddressone(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Enter Address 2 </label>
                            <input type='text' className='form-control' placeholder='Street Address 2'
                                value={addresstwo}
                                onChange={(e) => setaddresstwo(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select Country <span className="asterisk">*</span></label>
                            <select className={`form-control`} value={country} onChange={CountryHandle}>
                                <option value="">--Select country---</option>
                                {countryCodes.sort((a, b) => a.name.localeCompare(b.name)).map((country) => (
                                    <option key={country.id} value={country.name}>{country.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-4'>
    <div className='form-group'>
        <label className='selectlabel'>Select State <span className="asterisk">*</span></label>
        <select className={`form-control`} value={state} onChange={stateHandle}>
            <option value="">--Select State---</option>
            {stateList && stateList.map((list) => (
                <option key={list.id} value={list.id}>{list.name}</option> // Use list.id for value
            ))}
        </select>
    </div>
</div>

<div className='col-lg-4'>
    <div className='form-group'>
        <label className='selectlabel'>Select City <span className="asterisk">*</span></label>
        <select className={`form-control`} value={city} onChange={(e) => { setcity(e.target.value) }}>
            <option value="">--Select city---</option>
            {cityList && cityList.map((list) => (
                <option key={list.id} value={list.id}>{list.name}</option> // Use list.id for value
            ))}
        </select>
    </div>
</div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select Zipcode <span class="asterisk">*</span></label>
                            <input type='text' className={`form-control`} placeholder='Zipcode'
                                value={zipcode}
                                onChange={(e) => { setzipcode(e.target.value) }}
                                maxLength="5"
                                minLength="5"
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </div>
                    </div>

                    
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Enter Mobile Number <span class="asterisk">*</span></label>
                            <div className='d-flex'>
                                <div className='col-lg-4'>
                                <select
                                        className='form-control' style={{ width: '140%', marginLeft: '-15px', padding: '11px 0px', fontSize: '12px' }}
                                        value={countryCode} disabled
                                        onChange={(e) => setcountryCode(e.target.value)}
                                    >
                                        {countryCodes.map((country) => (
                                            <option key={country.code} value={country.code}>
                                                {country.code} ({country.name})
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='col-lg-8'>
                                    <input type='text' className={`form-control`} style={{ width: '118%', marginLeft: '-15px' }} placeholder='Mobile No.'
                                        value={phone}
                                        onChange={(e) => { setphone(e.target.value) }}
                                        maxLength="10"
                                        minLength="10"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>

           <div className='col-lg-4'>
               <div className='form-group'>
                   <label className='selectlabel'>Email <span class="asterisk">*</span></label>
                   <input type='email' className='form-control' placeholder='Email'
                   value={email}
                   onChange={(e)=>setemail(e.target.value)}
                  
                   />
               </div>
           </div>
           {/* <div className='col-lg-4'>
               <div className='form-group'>
                   <label>Phone</label>
                   <input type='text' className='form-control' placeholder='Phone'
                   maxLength="10"
                   minLength="10"
                   onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                    }}}
                   value={phone}
                   onChange={(e)=>setphone(e.target.value)}
                   />
               </div>
           </div> */}
          
    
       </div>
       <button className='formBtn' onClick={SaveHandle}>Update</button>
    
       </div>
    </div>
      )
}

export default EditCompany