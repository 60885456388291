import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sidebarAction } from '../../redux/reducer/SidebarSlice';
import { IMAGE } from '../../utils/Theme';
import Swal from "sweetalert2";
import moment from 'moment';
import ApiConnection from '../../utils/ApiConnection'
import { useNavigate } from 'react-router-dom'

const Topbar = () => {

    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    const sidebarReducer = useSelector((state) => state.sidebarSlice)
    const dispatch = useDispatch()

    let navigate = useNavigate()

    const SignOutHandle = async () => {
      const refreshToken = JSON.parse(localStorage.getItem('refresh_token'));

      Swal.fire({
          title: 'Are you sure log out?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, sign out it!'
      }).then(async (result) => {
          if (result.isConfirmed) {
              try {
                  const response = await ApiConnection.post('users/logout/', {
                      refresh_token: refreshToken
                  });
                  if (response.data.request_status === 1) {
                      sessionStorage.clear();
                      localStorage.clear();
                      // window.location.clear();
                      navigate('/');
                      window.location.reload();
                  } else {
                      Swal.fire('Error', 'Logout failed. Please try again.', 'error');
                  }
              } catch (error) {
                  console.error('Logout error:', error);
                  Swal.fire('Error', 'An error occurred while logging out.', 'error');
              }
          }
      });
  };

  return (
    <div className="topbar-header">
        <div className="row align-items-center">
        <div className="col-lg-2">
            <div className="brand-logo">
                <img src={IMAGE.c_logo} />
                <h5>Company Name</h5>
            </div>
            </div>
        <div className="col-lg-2">
            <button
            onClick={() =>
            dispatch(sidebarAction(sidebarReducer)) 
            }
    >
        {sidebarReducer?.isSidebarshow ? <i className="fa-solid fa-xmark"></i> :  <i className="fa-solid fa-bars"></i>  }

    </button>
        </div>
            <div className="col-lg-8">
            <ul className='header-right-m'>
                    <li>
                        Sign in as <span>{userinfo?.name}</span>
                    </li>
                    <li>
                        <button onClick={SignOutHandle}>Sign Out</button>
                    </li>
                </ul>
              </div>
        </div>
    </div>
  )
}

export default Topbar