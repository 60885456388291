import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useParams, useNavigate } from 'react-router-dom'
import { FetchTopicRequest } from '../../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../../utils/Loader'
import Swal from "sweetalert2";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'


const ViewTopic = () => {


     const dispatch = useDispatch()
     const { id } = useParams()
     const navigate = useNavigate();

     const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
     var userinfo = JSON.parse(localStorage.getItem('userinfo'))

     var viewdata = fetchacompanyReducer?.FetchTopicResponse?.results[0]

     console.log(viewdata)


     useEffect(() => {

          dispatch(FetchTopicRequest(id))

     }, [])

         // Function to convert plain text URLs to HTML links
    const convertUrlsToLinks = (text) => {
     const urlRegex = /(https?:\/\/[^\s]+)/g; // Regex to match URLs
     return text?.replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
 };

  // Function to convert HTML to clickable links
  const convertHtmlToLinks = (html) => {
     const tempDiv = document.createElement('div');
     tempDiv.innerHTML = html; // Set the HTML content

     // Extract text from both <p> and <tr> elements
     const paragraphs = Array.from(tempDiv.querySelectorAll('p'));
     const rows = Array.from(tempDiv.querySelectorAll('tr'));

     // Combine the text content from both types of elements
     const combinedContent = [...paragraphs, ...rows].map(element => {
         // Get inner text and trim it
         const text = element.innerText.trim();
         const urlRegex = /(https?:\/\/[^\s]+)/g; // Regex to match URLs

         // Check if the text contains a URL
         if (urlRegex.test(text)) {
             // Replace the URL with a clickable link
             return text.replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
         }
         return text; // Return the text as is if no URL is found
     }).filter(line => line.length > 0); // Filter out empty lines

     // Join lines with line breaks
     return combinedContent.join('<br/>');
 };

 const formattedHomeWorks = convertHtmlToLinks(viewdata?.home_works); // Convert homework data



  

     return (
          <div className='common-layout'>
               <Loader visible={fetchacompanyReducer.isloading} />
               <div className='innerlayout p-3'>
                    <div className='mb-4'>
                         <h3> View Topic</h3>
                    </div>

                    <div className='view-page'>

                         <div className='row'>
                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Topic Name:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.name}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Topic Code:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.code}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Topic Number:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.number}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Module Name:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.modules_name}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Group Name:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.group_name}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Syllabus Name:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.syllabus_name}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Coverage Days:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.coverage_days}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Student File:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                             {viewdata?.student_file_url ? (
                    <b>
                        <a href={viewdata.student_file_url} target='_blank' rel="noopener noreferrer">Download</a>
                    </b>
                ) : (
                    <span>N/A</span>
                )}
                                                  {/* <b><a href={viewdata?.student_file_url} target='_blank'>Download</a></b> */}
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Teacher File:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                             {viewdata?.teacher_file_url ? (
                    <b>
                        <a href={viewdata.teacher_file_url} target='_blank' rel="noopener noreferrer">Download</a>
                    </b>
                ) : (
                    <span>N/A</span>
                )}
                                                  {/* <b><a href={viewdata?.teacher_file_url} target='_blank'>Download</a></b> */}
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className='col-lg-6'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-6'>
                                                  <label className='d-block text-right'>Description:</label>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <b>{viewdata?.description}</b>
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              <div className='col-lg-12 homeworklink'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-3'>
                                                  <label className='d-block text-right'>Home Works : </label>
                                             </div>

                                        </div>
                                   </div>
                              </div>

                              <div className='col-lg-12'>
                                   <div className='form-group'>
                                        <div className='row align-items-center'>
                                             <div className='col-lg-12'>
                                                  <CKEditor
                                                       editor={Editor}
                                                       data={formattedHomeWorks}
                                                       disabled={true}
                                                       config={{ toolbar: [],
                                                       }}
                                                  />
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>


                    </div>

                    <div className='button-part mb-4'>
                         <ul>
                              <li>
                                   <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back to module</button>
                                   {/* <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/curriculum/syllabus`}><i className="fa-solid fa-angle-left"></i> Back to syllabus</NavLink> */}
                              </li>

                         </ul>
                    </div>

               </div>
          </div>
     )
}

export default ViewTopic